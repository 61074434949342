import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b3248d28"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_CgmDailyGlucoseCard = _resolveComponent("CgmDailyGlucoseCard")!
  const _component_CgmAccordionScoreList = _resolveComponent("CgmAccordionScoreList")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_EventsTable = _resolveComponent("EventsTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingComponent, {
          key: 0,
          spinnerColor: "lavender-700"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["p-grid--cgm-general", { 'p-grid--cgm-general--loading': _ctx.loading }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CgmDailyGlucoseCard, {
          dayStatistics: _ctx.dayStatistics,
          dayEvents: _ctx.dayEvents,
          onAnnotationEventClick: _ctx.handleAnnotationEventClick
        }, null, 8, ["dayStatistics", "dayEvents", "onAnnotationEventClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_CgmAccordionScoreList, {
          title: _ctx.$t('cgmDetail.data-day'),
          statistics: _ctx.dayStatistics
        }, null, 8, ["title", "statistics"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_HeadingComponent, {
          title: _ctx.$t('events.events-to-watch'),
          "sub-heading": true,
          divider: false
        }, null, 8, ["title"]),
        (_ctx.isErrorful)
          ? (_openBlock(), _createBlock(_component_GetRequestError, {
              key: 0,
              text: _ctx.$t('events.load-data-error')
            }, null, 8, ["text"]))
          : (_ctx.badEvents && _ctx.badEvents.length == 0)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 1,
                heading: _ctx.$t('events.no-data-title'),
                icon: "icon-alarm",
                text: _ctx.$t('events.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"]))
            : (_openBlock(), _createBlock(_component_EventsTable, {
                key: 2,
                events: _ctx.badEvents,
                "display-global-filters": false,
                fields: ['name', 'score', 'type', 'actions']
              }, null, 8, ["events"]))
      ])
    ], 2)
  ], 64))
}